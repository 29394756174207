import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en-gb';
import { markAllAsRead, notificationList } from '../../store/Notification/userNotificationsSlice';
import { FaBell } from 'react-icons/fa'; // Importing notification icon from react-icons

dayjs.extend(relativeTime);
dayjs.locale('en-gb');

const NotificationList = () => {
  const dispatch = useDispatch();
  const empId = localStorage.getItem("_id");
  const notifications = useSelector((state) => state.notifications.notifications);

  useEffect(() => {
      dispatch(notificationList(empId));
      dispatch(markAllAsRead(empId));
  }, [empId]);

  useEffect(() => {
    console.log(notifications);
  }, [notifications]);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <header className="bg-green-600 p-4 flex justify-between items-center rounded-md mb-6 shadow-md">
        <h1 className="text-2xl font-semibold text-white">Notifications List</h1>
      </header>
      <div className="bg-white  rounded-lg p-6">
        {notifications.length > 0 ? (
          <div className="max-w-full mx-auto mt-8 p-4 bg-white border border-gray-200 rounded-lg shadow-md">
            <ul className="w-full ml-2">
              {notifications.map((notification) => (
                <li
                  key={notification._id}
                  className="border-b border-gray-200 py-4 flex items-start"
                >
                  <FaBell className="text-green-600 mr-3" style={{width:"45px",height:"45px",alignSelf :"center"}} />
                  <div>
                    <h3 className="text-lg font-semibold">
                      {notification.title}
                    </h3>
                    <p className="text-gray-700 capitalize">{notification.body}</p>
                    <span className="text-sm text-gray-500">
                      {dayjs(notification.sentAt).fromNow()}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="text-center text-gray-600 mt-8">
            <FaBell className="text-green-600 mx-auto mb-4" size={40} />
            <p className="text-lg">No notifications found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationList;
